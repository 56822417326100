import React, { useRef, useState, useEffect } from "react";
import styles from "./styles/Topics.module.css";
import Navbar from "../../partials/Navbar";
import {
  BiPlusCircle,
  BiSave,
  BiTrash,
  BiX,
  BiPaperPlane,
} from "react-icons/bi";
import Loading from "../../components/Loading";
import axios from "axios";
import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";

const dummyLoad = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, 3000);
  });
};

const loadData = async () => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: JSON.parse(localStorage.getItem("profile")).email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let data = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/api/admin/mail-errors",
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "x-api-key": jwtToken,
        },
      }
    );
    return {
      data: data.data,
      status: data.status,
    };
  } catch (error) {
    if (error.response === undefined) {
      return {
        data: { data: [error.message] },
        status: 500,
      };
    } else {
      return {
        data: error.response,
        status: error.response.status,
      };
    }
  }
};

const delData = async ({ id }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: JSON.parse(localStorage.getItem("profile")).email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let data = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/api/admin/mail-errors/delete",
      {
        log_id: id,
        _method: "DELETE",
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "x-api-key": jwtToken,
        },
      }
    );
    return {
      data: data.data,
      status: data.status,
    };
  } catch (error) {
    if (error.response === undefined) {
      return {
        data: { data: [error.message] },
        status: 500,
      };
    } else {
      return {
        data:
          error.response.status === 404 ? ["Data not found"] : error.response,
        status: error.response.status,
      };
    }
  }
};

const resendReq = async ({ id }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: JSON.parse(localStorage.getItem("profile")).email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let data = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/api/admin/mail-errors/resend",
      {
        log_id: id,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "x-api-key": jwtToken,
        },
      }
    );
    return {
      data: data.data,
      status: data.status,
    };
  } catch (error) {
    if (error.response === undefined) {
      return {
        data: { data: [error.message] },
        status: 500,
      };
    } else {
      return {
        data:
          error.response.status === 404 ? ["Data not found"] : error.response,
        status: error.response.status,
      };
    }
  }
};

const UnsentMails = ({
  fnSetActive = () => {},
  fnSetLoginState = () => {},
  loginState,
}) => {
  const frameForm = useRef();
  const contentForm = useRef(new Array());
  const inputEls = useRef(new Array());
  const closePopUp = useRef();
  const tableContent = useRef();

  const [isLoading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    state: false,
    content: "",
    type: "",
  });
  const [showData, setData] = useState(null);

  const resetAlert = () => {
    setTimeout(() => {
      setAlert({ state: false, content: "", type: "" });
    }, 3000);
  };

  const handleHeightContent = () => {
    try {
      let height = window.innerHeight - 365;
      tableContent.current.style.maxHeight = height + "px";
      tableContent.current.style.overflow = "auto";
    } catch (error) {
      console.log(error);
    }
  };

  const handleRensend = (id) => {
    setLoading(true);
    resendReq({ id: id }).then((res) => {
      if (res.status === 202) {
        setData(res.data.data);
        setAlert({
          state: true,
          type: "success",
          content: "Email resend successfull !",
        });
        resetAlert();
      } else {
        if (res.status == 401) {
          fnSetLoginState(0);
        } else if (res.status === 405) {
          fnSetLoginState(3);
        } else {
          setAlert({
            state: true,
            type: "danger",
            content: res.data.error,
          });
          resetAlert();
        }
      }
      setLoading(false);
    });
  };

  const handleDelete = (id) => {
    setLoading(true);
    delData({ id: id }).then((res) => {
      if (res.status === 202) {
        setData(res.data.data);
        setAlert({
          state: true,
          type: "success",
          content: "Log deleted successfull !",
        });
        resetAlert();
      } else {
        if (res.status == 401) {
          fnSetLoginState(0);
        } else if (res.status === 405) {
          fnSetLoginState(3);
        } else {
          setAlert({
            state: true,
            type: "danger",
            content: res.data.error,
          });
          resetAlert();
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    handleHeightContent();
    window.addEventListener("resize", handleHeightContent);
    fnSetActive("unsent-mails");
  });

  useEffect(() => {
    if (showData === null && loginState === 1) {
      setLoading(true);
      loadData().then((res) => {
        if (res.status === 200) {
          setData(res.data.data);
        } else {
          if (res.status == 401) {
            fnSetLoginState(0);
          } else if (res.status === 405) {
            fnSetLoginState(3);
          } else {
            setData([]);
          }
        }
        setLoading(false);
      });
    }
  }, [showData, loginState]);

  return (
    <>
      <div className={`row ps-4 pe-4 pt-2 pb-2 ${styles.MainContainer}`}>
        <div className="col-12 mb-3 d-flex">
          <h5>Unsent Mails</h5>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {alert.state ? (
              <div className={`alert alert-${alert.type}`} role="alert">
                {alert.content}
              </div>
            ) : (
              <></>
            )}

            <div className="col-12 p-3 bg-white rounded-3">
              <div ref={tableContent}>
                <table className="table table-striped rounded-3">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "20%" }}>
                        Process Name
                      </th>
                      <th scope="col" style={{ width: "30%" }}>
                        Main Target
                      </th>
                      <th scope="col" style={{ width: "30%" }}>
                        Sec. Target
                      </th>
                      <th scope="col" className="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "20%" }}></th>
                      <th scope="col" style={{ width: "30%" }}></th>
                      <th scope="col" style={{ width: "30%" }}></th>
                      <th scope="col" className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {showData !== null &&
                      showData.map((data) => {
                        return (
                          <tr>
                            <td>{data.type}</td>
                            <td>{data.mail_target}</td>
                            <td>{data.mail_sec_target}</td>
                            <td className="d-flex justify-content-center gap-2">
                              <button
                                className="btn btn-warning"
                                onClick={() => {
                                  handleRensend(data.id);
                                }}
                              >
                                <BiPaperPlane />
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => {
                                  handleDelete(data.id);
                                }}
                              >
                                <BiTrash />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UnsentMails;
