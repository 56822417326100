import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/Login.module.css";
import Form from "react-bootstrap/Form";
import { Alert } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import "../App.css";
import { useNavigate } from "react-router-dom";
import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";
import Loading from "../components/Loading";

const handleSuccess = (res) => {
  return {
    data: res.data,
    status: res.status,
  };
};

const handleError = (error) => {
  if (error.response === undefined) {
    return {
      data: { data: [error.message] },
      status: 500,
    };
  } else {
    return {
      data: error.response,
      status: error.response.status,
    };
  }
};

const loginLoad = async ({ email, password }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/api/login",
      {
        email: email,
        password: password,
      },
      {
        headers: {
          "x-api-key": jwtToken,
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const checkAuth = async ({ token, email, OTP }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email
          ? email
          : JSON.parse(localStorage.getItem("profile")).email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        "/api/org/user-orgs" +
        (OTP ? "?otp_2fa=" + OTP : ""),
      {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("access_token"),
          "x-api-key": jwtToken,
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const reqOTP = async ({ token, email }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email
          ? email
          : JSON.parse(localStorage.getItem("profile")).email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/api/request-two-factor",
      {},
      {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("access_token"),
          "x-api-key": jwtToken,
        },
      }
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    // console.log(error);
    if (error.response === undefined) {
      return {
        data: { data: [error.message] },
        status: 500,
      };
    } else {
      return {
        data: error.response,
        status: error.response.status,
      };
    }
  }
};

const Login = ({
  fnSetUserData = () => {},
  fnSetActive = () => {},
  fnSetLoginState = () => {},
  loginState,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [ppageLoading, setPageLoading] = useState(false);
  const [isFailed, setFailedState] = useState({ state: false, msg: "" });
  const [captcha, setCaptchaState] = useState(false);
  const [userData, setUserData] = useState({
    email: null,
    token: null,
  });
  const [haveReqOtp, setHaveReqOtp] = useState(false);

  const email = useRef();
  const password = useRef();
  const otp = useRef();

  const navigate = useNavigate();

  const dummyLoad = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(false);
      }, 3000);
    });
  };

  const handleReqOTP = (token, email) => {
    setPageLoading(true);
    reqOTP({ token: token, email: email }).then((res) => {
      if (res.status === 401) {
        fnSetLoginState(0);
      } else if (res.status === 405) {
        setFailedState({
          state:
            res.data.data.message ===
            "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda"
              ? false
              : true,
          msg: res.data.data.message,
        });
      } else {
        setFailedState({
          state: true,
          msg: "Terjadi masalah dengan server. Silahkan coba minta OTP lagi !",
        });
      }
      setPageLoading(false);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !email.current.value ||
      email.current.value === "" ||
      !password.current.value ||
      password.current.value === "" ||
      !captcha
    ) {
      setLoading(false);
      setFailedState({ state: true, msg: "Semua field wajb diisi" });
      setTimeout(() => {
        setFailedState({ state: false, msg: "Semua field wajb diisi" });
      }, 3000);
    } else {
      loginLoad({
        email: email.current.value,
        password: password.current.value,
      }).then((res) => {
        if (res.status === 200 && res.data.admin) {
          // redirect page
          console.log(res);
          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("login_state", "true");
          localStorage.setItem(
            "profile",
            JSON.stringify({
              email: res.data.data.email,
              name: res.data.data.name,
              photo: res.data.data.photo,
            })
          );
          fnSetUserData(res.data.data);
          setUserData({
            email: res.data.data.email,
            token: res.data.access_token,
          });
          let token = res.data.access_token;

          checkAuth({
            token: res.data.access_token,
            email: email.current.value,
          }).then((res) => {
            setLoading(false);
            if (res.status === 200 || res.status === 404) {
              fnSetLoginState(1);
              window.location.href = "/";
            } else if (res.status === 405) {
              setHaveReqOtp(true);
              if (
                res.data.data.message ===
                "Kode autentikasi akan dikirim otomatis"
              ) {
                handleReqOTP(token, email.current.value);
              } else {
                setFailedState({
                  state: true,
                  msg: res.data.data.message,
                });
              }
              fnSetLoginState(3);
            } else {
              setFailedState({
                state: true,
                msg: Object.values(res.data.data).toString(),
              });
            }
          });

          // fnSetLoginState(1);
          // window.location.href = "/";
        } else {
          setLoading(false);
          setFailedState({
            state: true,
            msg: Object.values(res.data.data).toString(),
          });
        }
      });
    }
  };

  const handleSubmitOtp = (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    checkAuth({
      token: userData.token,
      OTP: otp.current.value,
      email: userData.email,
    }).then((res) => {
      setLoading(false);
      if (res.status === 200 || res.status === 404) {
        fnSetLoginState(1);
        window.location.href = "/";
      } else if (res.status === 405) {
        if (
          res.data.data.message === "Kode autentikasi akan dikirim otomatis"
        ) {
          handleReqOTP(userData.token, userData.email);
        } else {
          setFailedState({
            state: true,
            msg: res.data.data.message,
          });
        }
      } else {
        if (res.status === 401) {
          fnSetLoginState(0);
        }
        setFailedState({
          state: true,
          msg: Object.values(res.data.data).toString(),
        });
      }
    });
  };

  useEffect(() => {
    if (loginState === 3 && !haveReqOtp) {
      handleReqOTP(userData.token, userData.email);
      setHaveReqOtp(true);
    }
  }, [loginState, haveReqOtp]);

  return (
    <div className={`${styles.BgLayout}`}>
      <div className={`m-auto p-5 rounded-3 bg-white ${styles.BoxContent}`}>
        {loginState === 3 ? (
          ppageLoading ? (
            <Loading />
          ) : (
            <Form onSubmit={handleSubmitOtp}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <div className="w-100 d-flex">
                  <img
                    className={`${styles.LogoForm}`}
                    src="/images/logo.png"
                    alt=""
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h4 className="mb-4">Two Auth Factor</h4>
                {isFailed.state ? (
                  <Alert variant="danger">{isFailed.msg}</Alert>
                ) : (
                  <Alert variant="success">
                    Kode OTP telah dikirim ke alamat email anda !
                  </Alert>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>OTP Code</Form.Label>
                <Form.Control
                  ref={otp}
                  type="text"
                  placeholder="Enter OTP Code"
                />
              </Form.Group>
              <div
                className="text-primary pointer m-auto mb-2 text-center"
                onClick={() => {
                  handleReqOTP(userData.token, userData.email);
                }}
              >
                Tidak menerima OTP ? Kirim ulang
              </div>
              {isLoading ? (
                <button className="btn btn-primary w-100" disabled>
                  <Spinner className={styles.LoadingBtnIcon} /> Loading ...
                </button>
              ) : (
                <button className="btn btn-primary w-100">Submit</button>
              )}
            </Form>
          )
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className="w-100 d-flex">
                <img
                  className={`${styles.LogoForm}`}
                  src="/images/logo.png"
                  alt=""
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4 className="mb-4">Login</h4>
              {isFailed.state ? (
                <Alert variant="danger">{isFailed.msg}</Alert>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                ref={email}
                type="email"
                placeholder="Enter email"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                ref={password}
                type="password"
                placeholder="Password"
              />
            </Form.Group>
            <div className="d-flex">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onChange={(value) => {
                  setCaptchaState(value);
                }}
                className="mb-3 mx-auto"
              />
            </div>
            {isLoading ? (
              <button className="btn btn-primary w-100" disabled>
                <Spinner className={styles.LoadingBtnIcon} /> Loading ...
              </button>
            ) : (
              <button className="btn btn-primary w-100">Login</button>
            )}
          </Form>
        )}
      </div>
    </div>
  );
};

export default Login;
